@import '@/styles/variables';

$border-width-button: $border-width-base;

.base {
  display: inline-flex;
  justify-content: center;
  border-radius: $border-radius-base;
  border: $border-width-base solid transparent;
  padding: 8px 12px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}

.buttonSuccessIcon {
  margin: auto;
  color: #fff;
}

.buttonSuccessIcon {
  color: #fff;
  margin: auto;
}

// Sizes
.size_lg {
  padding: 8px 18px;
  line-height: 28px;
}

.size_md {
  padding: 6px 14px;
  line-height: 22px;
  font-size: 16px;
}

.size_s {
  padding: 4px 12px;
  line-height: 22px;
}

.size_full {
  width: 100%;
  text-align: center;
}

// Fonts

.font_s {
  font-size: $font-size-s;
}

.font_xs {
  font-size: $font-size-xs;
}


// Variants
.variant_outlined {
  background-color: transparent;
  border: $border-width-base solid $color-black;
  color: $font-color-dark;
  transition:
          color $transition-time $transition-variant,
          background-color $transition-time $transition-variant;

  &:hover {
    color: $font-color-light;
    background-color: $color-black;
  }
}

.variant_filled {
  background-color: #fff;
  border-color: #fff;
  color: $color-black;
}

// Shades
.variant_outlined.shade_dark {
  color: $font-color-dark;

  &:hover {
    color: $font-color-light;
  }
}

.variant_filled.shade_dark {
  background-color: $color-black-op-05;
  color: #fff;

  &:hover {
    background-color: $color-black;
  }
}

.shade_danger {
  color: #fff;
  background-color: rgba($color-red, 0.9);
  transition: background-color $transition-time $transition-variant;

  &:hover {
    background-color: rgba($color-red, 1);
  }
}

.shade_dark {
  border-color: $color-black;
}

.variant_ghost {
    background-color: transparent;
    border: none;
    color: inherit;

    &:hover {
      background-color: $color-gray-light;
    }
}

// Base states
.base:disabled,
.base[disabled] {
  pointer-events: none;
  background-color: $color-gray;
  border-color: $color-gray;
  color: $color-gray-dark
}

// Custom states
.base.base__isSuccess {
  background-color: $color-green!important;
  border-color: $color-green!important;
  color: #fff!important;
}

.base.base__withFocus:focus {
  border-color: $color-green-main;
  box-shadow: 0 0 6px 2px $color-green-main;
  outline: none;
}
